import React, { useState, useContext } from "react"
import { graphql, navigate } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import GeneralButton from "../components/generalButton"

import '../sass/app.scss';

const parseBlogPosts = (edges) =>
  edges
    .filter((edge) => edge.node.frontmatter.status)
    .map(({ node }) => ({
      id: node.id,
      group: node.frontmatter.group,
      date: node.frontmatter.date,
      description: node.frontmatter.description,
      imgDesk: node.frontmatter.imgDesk,
      imgMobile: node.frontmatter.imgMobile,
      lng: node.frontmatter.lng,
      filepath: node.frontmatter.path,
      status: node.frontmatter.status,
      title: node.frontmatter.title,
      html: node.html
    }))

const PostTemplate = ({ pageContext, data, location }) => {
  const { language } = useI18next();
  const allPosts = data.allMarkdownRemark.edges
  function getGroup() {
    if (typeof window !== `undefined`) {
      return localStorage.getItem('group')
    } else return false;
  }


  const [visitedGroup, setVisitedGroup] = useState(getGroup())
  const [atualGroup] = useState(pageContext.group)
  var newPost = null

  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)

  const { t } = useTranslation()
  const breakpoints = useBreakpoint();

  if (parseInt(visitedGroup) === atualGroup && (language !== pageContext.lng) && typeof window !== `undefined`) {
    newPost = parseBlogPosts(allPosts).filter(function (el) {
      return (el.lng === language && el.group === atualGroup)
    })[0]

    navigate(translateUrl(newPost.filepath, language))
  }
  else if (visitedGroup === undefined && typeof window !== `undefined`) {
    setVisitedGroup(atualGroup)
    localStorage.setItem('group', atualGroup)
  }

  else {
    if (typeof window !== `undefined`)
      localStorage.setItem('group', atualGroup)
    const mobile = breakpoints.mobile

    const myArray = pageContext.description.split(" ");
    const leng = myArray.length;
    var arr = myArray.map((i) => i + " ");
    const middleIndex = Math.ceil(leng / 2);

    const firstHalf = arr.splice(0, middleIndex);
    const secondHalf = arr.splice(-middleIndex);

    var textStatic = {
      "title": pageContext.title,
      "top": {
        "slogan": {
          "blue": ["  "],
          "first": firstHalf,
          "second": secondHalf
        },
        "sloganmobile": {
          "blue": ["  "],
          "first": [pageContext.description],
          "second": [],
          "third": [],
          "fourth": []
        }
      }
    }

    return (
      <Layout mobile={mobile} post={pageContext.imgDesk} data={t("global", { returnObjects: true })} page={t("blog", { returnObjects: true }).page}>
        <Seo seo={t("blog", { returnObjects: true }).seo} />
        {mobile ?
          <>
            <MenuGlobal img src={pageContext.imgMobile} location={location}
              global={t("global", { returnObjects: true })} textstatic={textStatic} centered={true} mobile />

            <SmoothScroll>
              <div className=" container-margin-blog ">
                <div
                  className="description ILightBeta blog-html white line-height-2 m-auto text-justify"
                  dangerouslySetInnerHTML={{ __html: pageContext.html }}
                />
              </div>

              <div className="mb-5">
                <GeneralButton text={t("blog", { returnObjects: true }).posts.back} url='/blog' center={true} />
              </div>

              <div className="container-scroll-heigth" />

            </SmoothScroll>
          </>
          :
          <>
            <MenuGlobal img src={pageContext.imgDesk} location={location}
              global={t("global", { returnObjects: true })} textstatic={textStatic} centered={false} />

            <SmoothScroll>
              <div
                className="description ILightBeta blog-html white container-margin-blog  m-auto text-justify"
                dangerouslySetInnerHTML={{ __html: pageContext.html }}
              />

              <div className="mb-5">
                <GeneralButton text={t("blog", { returnObjects: true }).posts.back} url='/blog' center={true} />
              </div>

              <div className="container-scroll-heigth" />

            </SmoothScroll>
          </>
        }

      </Layout>
    )
  }
}


export default PostTemplate


export const pageQuery = graphql`
    query($language: String!) {
      locales: allLocale(filter: {ns: {in: ["global", "blog"]}, language: {eq: $language}}) {
      edges {
      node {
      ns
          data
    language
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            imgDesk
            imgMobile
            status
            description
            lng
            group
          }
        }
      }
    }
  }
`